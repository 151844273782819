.round_photo-wrapper {
  position: relative;
  max-width: 140px;
  height: 140px;
  margin-bottom: 4em;
}
.profile-details {
  position: absolute;
  z-index: 99;
  background-color: var(--grey-color);
  min-width: 300px;
  height: auto;
  top: 100%;
  margin-top: -20px;
  margin-left: 30px;
  height: auto;
  padding: 0.5em 1em 0.75em 0.6em;
  transform: translateX(-100%);
  opacity: 0;
  transition: all 0.5s ease-in-out;
}
.round_photo-wrapper:hover .profile-details {
  transform: translateX(0);
  opacity: 1;
}
.profile-details h5 {
  display: flex;
  align-items: center;
}
.profile-details .phone {
  margin-right: 0.5em;
  font-size: 1.5em;
}
.profile-details p {
  margin-top: 0.75em;
}
.photo-content {
  position: relative;
  max-width: 140px;
  height: 140px;
  border-radius: 50%;
  overflow: hidden;
}
.photo-content .staffphoto {
  width: 100%;
  height: 140px;
  border-radius: 50%;
}
.photo-content .abt-pos {
  position: absolute;
  right: -40px;
  bottom: 20px;
  width: 150px;
  padding: 0.25em 2em 0.25em 0.25em;
  background-image: linear-gradient(rgba(0, 66, 32, 0.8), rgba(0, 66, 32, 0.6));
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.2);

  display: flex;
  display: -ms-flexbox;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  line-height: 0.75;
}

.team-head .photo-content .abt-pos {
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 0.6)
  );
}

.team-head .photo-content .abt-pos h5 {
  color: var(--primary-color);
}
.photo-content .abt-pos h6 {
  font-weight: 400;
  font-size: 0.5rem;
}
.team-head .photo-content .abt-pos h6 {
  color: black;
  font-weight: 500;
}
.photo-content .abt-pos h5 {
  font-weight: 700;
  font-size: 0.6rem;
}

.photo-content .abt-pos h6 {
  font-weight: 400;
  font-size: 0.5rem;
}

@media (min-width: 768px) {
  .slide-left .profile-details {
    transform: translateX(-100%);
  }

  .round_photo-wrapper:hover .slide-left .profile-details {
    transform: translateX(0);
    opacity: 1;
  }
  .profile-details {
    min-width: 200px;
  }

  .profile-details .phone {
    font-size: 1.25em;
  }

  .profile-details h5 {
    font-size: 0.5em;
  }
}
@media (min-width: 992px) {
  .round_photo-wrapper {
    max-width: 160px;
    height: 160px;
  }

  .photo-content {
    max-width: 160px;
    height: 160px;
  }
  .photo-content .staffphoto {
    width: 100%;
    height: 160px;
  }
  .photo-content .abt-pos {
    right: -75px;
    line-height: 0.7;
    width: 200px;
    margin-inline: 0 auto;
    display: block;
    padding: 0.35em 2.5em 0.35em 0.5em;
  }
  .profile-details {
    min-width: 240px;
    margin-top: -20px;
    margin-left: 35px;
    padding: 0.5em 0.6em 0.75em;
  }
  .photo-content .abt-pos h5 {
    font-size: 0.7rem;
    line-height: 0.8;
  }
  .photo-content .abt-pos h6 {
    font-size: 0.6rem;
  }
  .profile-details .phone {
    font-size: 1em;
  }

  .profile-details h5 {
    font-size: 0.58em;
  }
}
@media (min-width: 1200px) {
  .photo-content .abt-pos {
    padding: 0.4em 2em 0.4em 0.5em;
  }

  .profile-details {
    padding: 0.75em 0.75em 0.75em;
    min-width: 280px;
  }
  .profile-details h5 {
    font-size: 0.75rem;
  }

  .profile-details {
    margin-left: 50px;
    margin-top: -22px;
    margin-left: 35px;
  }
}
@media (min-width: 1600px) {
  .slide-left .profile-details {
    transform: translateX(-100%);
  }
  .profile-details {
    min-width: 350px;
  }
}
