.photo-container {
  width: 100%;
  position: relative;
  max-width: 600px;
  margin-inline: auto;
}
.photo-container .img {
  width: 100%;
  height: auto;
}

.absolute-position {
  position: absolute;
  right: 0;
  bottom: 40px;
  width: auto;
  padding: 0.25em 2.5em;
  background-image: linear-gradient(
    rgba(0, 66, 32, 0.8),
    rgba(141, 181, 140, 0.6)
  );
  display: flex;
  display: -ms-flexbox;
  justify-content: center;
  align-items: center;
}

.absolute-position h6 {
  font-weight: 600;
}

@media (min-width: 600px) {
  .photo-container {
    max-width: 400px;
  }
}
@media (min-width: 768px) {
  .photo-container {
    max-width: 600px;
  }
}

@media (min-width: 1600px) {
  .photo-container {
    max-width: 350px;
  }
}
