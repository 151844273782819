.career-page {
  font-weight: 800;
  font-size: 0.8em;
  margin-bottom: 0;
  line-height: 1;
}
.career-page h4.light-green-color {
  font-weight: 800;
  font-size: 0.8em;
  margin-bottom: 0;
  line-height: 1;
}

.career-page h1.green-color {
  font-weight: 900;
  font-size: 1.75em;
  line-height: 1;
  max-width: 340px;
  margin-inline: 0 auto;
}

.career-page .row {
  margin-top: 2em;
  position: relative;
}
.career-page img.Nurse {
  max-width: 400px;
  margin-inline: auto;
  display: block;
}
.career-page .career-text {
  max-width: 400px;
  margin-inline: auto;
  padding: 1em;
}
.career-page .career-text h3 {
  font-weight: 300;
  max-width: 200px;
  margin-inline: auto 0;
  text-align: right;
  text-transform: uppercase;
}
.career-page .career-text p {
  text-align: justify;
  margin-bottom: 1em;
  color: var(--primary-color);
}

/***CAREER APPLICATION FORM***/
.career-page .position-left {
  padding: 1em;
  margin-top: 2em;
  max-width: 500px;
  margin-inline: auto;
}
/**for other form setting please refer to the REQUEST APPOINTMENT FORM**/
#upload_cover_letter {
  display: none;
}
#upload_cv {
  display: none;
}

.career-page .form-row .btn {
  padding: 0.75em 0.75em;
  display: block;
  font-size: 0.55rem;
  font-weight: 300;
  width: 50%;
  background-color: var(--white-color);
  cursor: auto;
  margin: 0;
  font-family: var(--inter-font);
}
.career-page .form-row .relative-btn {
  background-color: var(--secondary-color);
  color: var(--primary-color);
}
.career-page .form-row .label {
  cursor: pointer;
  font-size: 0.6rem;
  margin: 0;
  font-weight: 300;
}

.career-page .form-row .select-file-btn {
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
}
.career-page .Submit-btn {
  width: 100%;
  text-align: right;
}
.career-page .Submit-btn button {
  font-size: 0.6rem;
  padding: 0.5em 2em;
  background-color: var(--secondary-color);
}

@media (min-width: 600px) {
  .career-page h4.light-green-color {
    font-weight: 900;
    font-size: 1em;
  }
  .career-page h1.green-color {
    font-size: 1.8em;
    line-height: 1.1;
    max-width: 450px;
  }
  .career-page img.Nurse {
    max-width: 400px;
    margin-inline: auto;
    display: block;
  }
  .career-page .career-text {
    max-width: 500px;
    padding: 1.5em;
    margin-inline: auto;
    display: block;
  }
  .career-page .career-text h3 {
    font-weight: 300;
    max-width: 250px;
    margin-inline: auto 0;
    text-align: right;
    text-transform: uppercase;
  }

  /***CAREER APPLICATION FORM***/
  .career-page .position-left {
    padding: 1em 2em;
    max-width: 500px;
  }
}

@media (min-width: 768px) {
  .career-page h1.green-color {
    font-size: 2em;
    line-height: 1;
    max-width: 425px;
    margin-inline: 0 auto;
  }
  .career-page img.Nurse {
    max-width: 450px;
    margin-inline: 0 auto;
  }
  .career-page .row .col-40 {
    width: 48%;
  }
  .career-page .row .col-60 {
    width: 52%;
  }
  .career-page .career-text {
    padding-bottom: 3em;
    position: relative;
  }
  /***CAREER APPLICATION FORM***/
  .position-left .form-width h3.light-green-color {
    font-weight: 300;
    margin-bottom: 2em;
  }
  .career-page .position-left {
    padding: 1em 1.5em;
    max-width: 550px;
    margin-inline: 0 auto;
    position: relative;
    background-color: var(--primary-color);
    top: -100px;
    z-index: 99;
  }
}

@media (min-width: 992px) {
  .career-page .row .col-40 {
    width: 40%;
  }
  .career-page .row .col-60 {
    width: 60%;
  }
  .career-page h4.light-green-color {
    font-size: 1.2em;
  }
  .career-page h1.green-color {
    font-size: 2.2em;
    max-width: 550px;
  }
  .career-page .position-left {
    top: -110px;
  }
  .career-page img.Nurse {
    position: relative;
    bottom: -55px;
  }
}

@media (min-width: 1200px) {
  .career-page h1.green-color {
    max-width: 550px;
  }
  .career-page img.Nurse {
    max-width: 500px;
  }
  .career-page .position-left {
    padding: 1em 3em;
    max-width: 700px;
    top: -160px;
  }
  .career-page .career-text {
    max-width: 550px;
    margin-inline: auto 0;
    padding: 2.5em 2.5em 6em 2.5em;
  }
}
@media (min-width: 1600px) {
  .career-page h1.green-color {
    max-width: 600px;
  }
  .career-page img.Nurse {
    max-width: 550px;
    margin-inline: 0 auto;
    bottom: -15px;
  }
  .career-page .position-left {
    padding: 1em 5em;
    max-width: 750px;
    top: -160px;
    margin-inline: 0 auto;
  }
  .career-page .career-text {
    max-width: 700px;
    margin-inline: auto 0;
    padding: 3em 4em 6em 4em;
  }
  .career-page {
    max-width: 1200px;
    margin-inline: auto;
  }
}
