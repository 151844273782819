.title-wrapper {
  text-align: center;
  padding: 2.5em 0;
}

@media (min-width: 992px) {
  .title-wrapper {
    padding: 3.5em 0;
  }
}
