.Accreditation-logo {
  max-width: 150px;
  margin-inline: auto;
  display: block;
}
.acc-wrapper h5 {
  text-align: center;
  margin-top: 1em;
}

@media (min-width: 768px) {
  .Accreditation-logo {
    margin-inline: 0 auto;
  }
  .acc-wrapper h5 {
    text-align: left;
    margin-top: 0;
  }
  .Our_story .acc-wrapper .flex-row {
    align-items: flex-start;
  }
}
@media (min-width: 1200px) {
  .Accreditation-logo {
    max-width: 180px;
  }

  .acc-wrapper .col-30 {
    width: 25%;
  }
  .acc-wrapper .col-70 {
    width: 75%;
  }
}
@media (min-width: 1600px) {
  .Accreditation-logo {
    max-width: 200px;
  }

  .acc-wrapper .col-30 {
    width: 20%;
  }
  .acc-wrapper .col-70 {
    width: 80%;
  }
}
