.testifier-header-text {
  margin-bottom: 0;
}

.testifiers {
  margin-top: 1em;
  margin-bottom: 1em;
}
.testifiers .colum-reverse {
  flex-direction: column-reverse;
}
.testifiers {
  margin-top: 1em;
  margin-bottom: 1em;
}
.testifiers .row .column {
  flex: 100%;
  padding: 0;
}

.from-left {
  width: 70%;
  margin-inline: 0 auto;
  display: block;
}
.from-right {
  width: 70%;
  margin-inline: auto 0;
  display: block;
}

@media (min-width: 756px) {
  .testifier-header-text {
    margin-bottom: 0.25em;
  }
  .testifiers {
    margin-top: 2em;
  }
  .testifiers .row {
    flex-direction: column;
  }
  .testifiers .colum-reverse {
    flex-direction: column-reverse;
  }
  .testifiers .row .col-70 {
    width: 100%;
  }
  .testifiers .row .col-30 {
    width: 100%;
  }
  .testifiers .row .column {
    flex: 50%;
    padding: 0 4px;
  }
}
@media (min-width: 992px) {
  .testifier-header-text {
    margin-bottom: 0;
  }
  .testifiers .row {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .testifiers .row .col-70 {
    width: 59.5%;
  }
  .testifiers .row .col-30 {
    width: 39.5%;
  }
}
@media (min-width: 1200px) {
  .testifiers .row .column {
    flex: 33.33%;
  }
  .testifiers .row .col-70 {
    width: 70%;
  }
  .testifiers .row .col-30 {
    width: 30%;
  }
}
