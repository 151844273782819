.Reason-tab-wrapper {
  margin-bottom: 2em;
}

.Reason-tab-wrapper .width-30 {
  width: 30%;
}
.Reason-tab-wrapper .width-70 {
  width: 70%;
}

.Reason-tab-wrapper .tab-content {
  box-shadow: 1px 1px 1px 2px rgba(0, 0, 0, 0.1);
  padding: 0.8em;
  position: relative;
}
.Reason-tab-wrapper .tab-content .absolute-box {
  width: 100%;
  max-width: 80px;
  position: absolute;
  top: -20px;
  left: 16px;
  z-index: 99;
  border-radius: 12px;
}
.Reason-tab-wrapper .tab-content .absolute-box .flex {
  flex-direction: column;
  line-height: 0;
  padding: 0.5em 0;
}
.Reason-tab-wrapper .tab-content .absolute-box .flex h4 {
  font-weight: 800;
  font-size: 1.4em;
  margin-bottom: 0.25em;
}
.Reason-tab-wrapper .tab-content .absolute-box .flex h6 {
  font-weight: 600;
  font-size: 0.7em;
}
.Reason-tab-wrapper .tab-content .short-story h5 {
  font-weight: 600;
  line-height: 1;
}
.Reason-tab-wrapper .tab-content .short-story p {
  margin-bottom: 0;
  line-height: 1.2;
}
.Reason-tab-wrapper .tab-content .short-story a.light-green-color {
  font-size: 0.7rem;
  line-height: 1;
  font-weight: 600;
}
