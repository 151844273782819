img.map_figure {
  max-width: 400px;
}

.Map_and_direction h5 {
  font-weight: 700;
}

.Map_and_direction button {
  background-color: var(--secondary-color);
  color: var(--primary-color);
  font-weight: 600;
}
.Map_and_direction p b {
  font-weight: 700;
  color: var(--secondary-color);
}
.google-map-code {
  position: relative;
  margin-bottom: 0;
}
.fade {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.6));
  z-index: 9;
}
#googleMap {
  width: 100%;
  max-width: 800px;
  height: 400px;
}

.abt-btn {
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.abt-btn button {
  width: 200px;
  height: 90px;
  font-size: 0.65em;
  background-color: var(--primary-color);
  color: var(--white-color);
  display: flex;
  align-items: center;
}

@media (min-width: 600px) {
  img.map_figure {
    max-width: 600px;
  }
}

@media (min-width: 768px) {
  #googleMap {
    max-width: 100%;
  }
}
@media (min-width: 992px) {
  img.map_figure {
    max-width: 700px;
  }

  #googleMap {
    height: 500px;
  }
  .abt-btn {
    z-index: 10;
    top: 50%;
    left: 72%;
    transform: translate(-50%, -72%);
  }

  .abt-btn button {
    width: 220px;
    height: 100px;
    font-size: 0.7em;
    background-color: var(--primary-color);
    color: var(--white-color);
    display: flex;
    align-items: center;
  }
  #angle-right {
    font-size: 2em;
    margin-left: 0.25em;
  }

  .abt-btn button:hover #angle-right {
    animation-name: animate-icon;
    animation-duration: 1s;
    animation-iteration-count: infinite;
  }

  @keyframes animate-icon {
    from {
      transform: translateX(10px);
      opacity: 1;
    }
    to {
      transform: translateX(-10px);
      opacity: 0.5;
    }
  }

  @-webkit-keyframes animate-icon {
    from {
      transform: translateX(10px);
      opacity: 1;
    }
    to {
      transform: translateX(-10px);
      opacity: 0.5;
    }
  }
  @-moz-keyframes animate-icon {
    from {
      transform: translateX(10px);
      opacity: 1;
    }
    to {
      transform: translateX(-10px);
      opacity: 0.5;
    }
  }
}
@media (min-width: 1200px) {
  img.map_figure {
    max-width: 800px;
  }
  #googleMap {
    height: 600px;
  }

  .abt-btn button {
    width: 280px;
    height: 125px;
    font-size: 0.8em;
  }
  #angle-right {
    font-size: 2.5em;
    margin-left: 0.25em;
  }
}

@media (min-width: 1600px) {
  .abt-btn button {
    width: 300px;
    height: 150px;
    font-size: 0.9em;
  }
  #angle-right {
    font-size: 3em;
  }
}
