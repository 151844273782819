.footer-wrapper {
  border-top: 4px solid var(--variant-secondary-color);
}
.footer-wrapper .row {
  margin-bottom: 1.5em;
}
.footer-wrapper .row:last-child {
  margin-bottom: 0;
}

.footer-wrapper .small-width {
  width: 100%;
  max-width: 400px;
  margin-inline: 0 auto;
}
.footer-wrapper .sage-clinic {
  font-weight: 800;
}
.footer-wrapper p {
  margin-bottom: 0;
}
.footer-wrapper span {
  width: 100%;
  display: block;
}
.footer-wrapper .col-30 {
  margin-bottom: 1.75em;
}
.footer-wrapper .col-30:last-child {
  margin-bottom: 0;
}

.footer-wrapper h6 {
  font-weight: 600;
  margin-bottom: 0;
}
.footer-wrapper ul li {
  line-height: 1.4;
}
.footer-wrapper .remove-decor {
  color: var(--white-color);
}
.footer-wrapper .d-block {
  width: 100%;
  max-width: 200px;
  margin-inline: 0 auto;
  margin-bottom: 1em;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.footer-wrapper .cont-pri-smallwidth {
  width: 100%;
}
.footer-wrapper .d-block span {
  font-size: 0.65em;
  display: none;
}
.footer-wrapper .d-block .ul-link {
  display: inline-block;
}
.footer-wrapper .d-block .ul-link:nth-child(odd):after {
  content: "|";
  color: var(--secondary-color);
  font-size: 1em;
  font-weight: 700;
  margin-inline: 0.5em;
}
small {
  text-align: center;
  width: 100%;
  font-size: 0.55em;
  display: block;
}
small span.copywrite {
  width: 100%;
  max-width: 250px;
  margin-inline: auto;
  line-height: 1.4;
  font-weight: 600;
  margin-bottom: 1em;
}

small .green-line {
  display: block;
  width: 150px;
  margin-inline: auto;
  background-color: var(--secondary-color);
  height: 2px;
  margin-bottom: 1em;
}
small .remove-decor b {
  color: var(--secondary-color);
}

@media (min-width: 768px) {
  .footer-wrapper .row {
    margin-bottom: 2em;
  }
  .footer-wrapper .small-width {
    width: 100%;
    max-width: 800px;
  }
  .footer-wrapper span {
    width: auto;
    display: inline;
  }
  .footer-wrapper .col-40 h6 {
    text-align: right;
  }
  .footer-wrapper .d-block {
    margin-inline: auto 0;
  }
  small {
    text-align: left;
  }
  small span.copywrite {
    width: auto;
    max-width: 0;
    margin-inline: 0;
    line-height: 1.4;
    font-weight: 400;
    margin-bottom: 0;
  }
  small span.copywrite::after {
    content: "|";
    color: var(--secondary-color);
    font-size: 1em;
    font-weight: 700;
    margin-inline: 0.5em;
  }
  small .green-line {
    display: none;
  }
}

@media (min-width: 1200px) {
  .footer-wrapper .sage-clinic {
    font-size: 1.2em;
    font-weight: 900;
  }
}
@media (min-width: 1600px) {
  .footer-wrapper .sage-clinic {
    font-size: 1.5em;
  }
}
