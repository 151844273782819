.Patient_feedback .container-relative {
  position: relative;
}
.Patient_feedback .sm-width {
  width: 100%;
  max-width: 600px;
}
.Patient_feedback .form-width-header {
  max-width: 600px;
  line-height: 1;
  margin-bottom: 0.5em;
}
.Patient_feedback .feedback-image {
  max-width: 600px;
}

.Patient_feedback .form-width h3 {
  font-weight: 400;
}
.Patient_feedback .form-width {
  width: 100%;
  max-width: 600px;
  margin-inline: 0 auto;
  margin-top: 2em;
  margin-bottom: 0.25em;
  padding: 1em 0.5em;
}

.Patient_feedback .form-width label {
  font-weight: 400;
  color: var(--white-color);
}

.Patient_feedback .form-row {
  margin-bottom: 0.5em;
  width: 100%;
}
.Patient_feedback .form-row input[type="text"],
.Patient_feedback .form-row textarea {
  width: 100%;
  border: none;
  outline: none;
  background-color: var(--grey-color);
  padding: 0.75em 0.5em;
  display: inline-block;
  resize: none;
  transition: all 1000ms;
}
.Patient_feedback .form-row input[type="text"]:focus,
.Patient_feedback .form-row textarea:focus {
  border: none;
  outline: none;
  background-color: #c7e2c6;
}

.Patient_feedback .form-row .col-49_5 {
  margin-bottom: 0.5em;
}
.Patient_feedback .form-row button {
  float: none;
  margin-top: 1em;
  font-weight: 500;
}
.Patient_feedback .form-row button:hover {
  background-color: var(--white-color);
  color: var(--green-color);
}
.Patient_feedback .form-row:last-child {
  text-align: right;
}
.Patient_feedback .form-row:last-child label {
  text-align: left;
  margin-bottom: 0.75em;
}
.Patient_feedback .feedback-line {
  padding: 1em 0.5em;
}
.Patient_feedback .feedback-line h4 {
  color: var(--primary-color);
  font-weight: 700;
}

@media (min-width: 992px) {
  .Patient_feedback .container-relative {
    margin-bottom: 800px;
  }
  .Patient_feedback .form-width {
    padding: 0 0.5em;
  }
  .Patient_feedback .form-width {
    max-width: 700px;
  }
  .Patient_feedback .form-width-header {
    max-width: 500px;
  }

  .Patient_feedback .position-right.feedback-line {
    max-width: 700px;
    margin-top: 25px;
  }
  .Patient_feedback .sm-width {
    max-width: 400px;
    padding-right: 5em;
  }
  .Patient_feedback .feedback-image {
    max-width: 500px;
    position: absolute;
    width: 100%;
    top: 160px;
    right: 0;
    z-index: 1;
  }
  .Patient_feedback .position-left.bg-green {
    max-width: 500px;
    position: absolute;
    width: 100%;
    top: 300px;
    left: 0;
    z-index: 2;
  }
  .Patient_feedback .feedback-line {
    max-width: 500px;
    position: absolute;
    width: 100%;
    top: 800px;
    right: 0;
    z-index: 3;
    padding: 1em 0 1em 2.5em;
  }
}
@media (min-width: 1200px) {
  .Patient_feedback .container-relative {
    margin-bottom: 850px;
  }
  .Patient_feedback .form-width {
    max-width: 800px;
  }
  .Patient_feedback .sm-width {
    max-width: 525px;
    padding-right: 4.5em;
  }
  .Patient_feedback .feedback-image {
    max-width: 600px;
  }
  .Patient_feedback .position-left.bg-green {
    max-width: 600px;
  }
  .Patient_feedback .feedback-line {
    max-width: 600px;
    top: 810px;
  }
  .Patient_feedback .position-right.feedback-line {
    margin-top: 35px;
  }
}
@media (min-width: 1400px) {
  .Patient_feedback .container-relative {
    margin-bottom: 900px;
  }
  .Patient_feedback .form-width {
    max-width: 900px;
  }
  .Patient_feedback .sm-width {
    max-width: 500px;
    padding-right: 2em;
  }
  .Patient_feedback .feedback-image {
    max-width: 700px;
  }
  .Patient_feedback .position-left.bg-green {
    max-width: 750px;
    padding-inline: 1em;
  }
  .Patient_feedback .colum-80 {
    width: 100%;
  }
  .Patient_feedback .feedback-line {
    top: 830px;
  }
  .Patient_feedback .position-right.feedback-line {
    max-width: 1000px;
    margin-top: 10px;
  }
}
@media (min-width: 1600px) {
  .Patient_feedback .sm-width {
    max-width: 600px;
  }
  .Patient_feedback .feedback-image {
    max-width: 800px;
  }
}
