.faq-accoridion-wrapper {
  padding: 0.5em 0;
}

.faq-accoridion-wrapper .accordion-btn {
  cursor: pointer;
  width: 100%;
  background-color: inherit;
  text-align: left;
  padding: 0;
  font-weight: 300;
}
.faq-accoridion-wrapper .accordion-btn::after {
  content: "\002B";
  font-size: 1em;
  float: right;
  font-weight: 300;
  color: var(--secondary-color);
}
.faq-accoridion-wrapper .acc-activated::after {
  content: "\2212";
}

.faq-accoridion-wrapper .accordionpanel {
  overflow: hidden;
  max-height: 0;
  transition: all 0.5s ease;
  width: 100%;
  padding: 0 18px;
}
.faq-accoridion-wrapper .accordionpanel .content-wrapper {
  padding: 0.5em;
}
