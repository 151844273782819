.back-to-top-btn {
  background: rgb(0, 66, 32);
  background: linear-gradient(
    180deg,
    rgba(0, 66, 32, 1) 21%,
    rgba(141, 181, 140, 1) 62%
  );
  position: fixed;
  bottom: 150px;
  right: 40px;
  z-index: 999;
  width: 45px;
  height: 45px;
  border-radius: 52% 49% 53% 47% / 77% 77% 26% 27%;
  display: flex;
  justify-content: center;
  box-shadow: 1px 1px 1px 1px rgba(12, 12, 12, 0.4);
  transform: translateY(-250px);
  opacity: 0;
  transition: transform 0.75s ease-in-out, opacity 1s ease-in;
  text-align: center;
  visibility: hidden;
}

#topBTN {
  color: var(--white-color);
  font-size: 1.25em;
  margin-top: -25px;
}
.scrollTopBtn {
  border: none;
  outline: none;
  cursor: pointer;
  display: inline-block;
  background-color: inherit;
  font-size: 1em;
}

.translate-btn {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}

@media (min-width: 768px) {
  .back-to-top-btn {
    right: 80px;
  }
}
@media (min-width: 992px) {
  #topBTN {
    font-size: 1.25em;
  }
}
@media (min-width: 1200px) {
  #topBTN {
    font-size: 1.35em;

    margin-top: -300px;
  }
}
@media (min-width: 1600px) {
  #topBTN {
    margin-top: -200px;
  }
}
