.Box {
  width: 100px;
  height: 40px;
  padding: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Box h5 {
  margin-bottom: 0.25em;
  line-height: 1;
  box-shadow: inset;
  font-weight: 600;
  max-width: 100px;
  text-align: center;
  text-transform: uppercase;
  font-size: 0.6rem;
}

@media (min-width: 600px) {
  .Box {
    width: 140px;
    min-height: 60px;
    padding: 0.25em;
    margin-inline: auto;
  }
  .Box h5 {
    margin-bottom: 0.25em;
    margin-top: 0.25em;
  }
}
@media (min-width: 768px) {
  .Box {
    width: 100px;
    min-height: 55px;
    margin-inline: auto 0;
  }
}
@media (min-width: 992px) {
  .Box {
    min-height: 60px;
    margin-inline: auto 0;
  }
}
@media (min-width: 1200px) {
  .Box {
    width: 140px;
  }
  .Box h5 {
    font-size: 0.65rem;
  }
}
@media (min-width: 1600px) {
  .Box {
    width: 180px;
  }
}
