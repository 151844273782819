.comment {
  padding: 1em;
  margin-bottom: 1em;
  width: 100%;
  max-width: 600px;
  margin-inline: auto;
}
@media (min-width: 600px) {
  .comment {
    max-width: 400px;
  }
}
@media (min-width: 768px) {
  .comment {
    max-width: 600px;
  }
}
@media (min-width: 992px) {
  .comment {
    margin-bottom: 0;
    margin-inline: 0 auto;
  }
  .odd-comment .comment {
    margin-inline: auto 0;
  }
}
@media (min-width: 1200px) {
  .comment {
    max-width: 700px;
  }
}
@media (min-width: 1600px) {
  .comment {
    max-width: 900px;
    padding: 2em 1em;
  }
}
